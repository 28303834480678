import { Outlet } from "react-router-dom"
import React from "react"
import { AppFooter } from "../components/app-footer/AppFooter"
import { AppSidebar } from "../components/app-sidebar/AppSidebar"
import { Clock } from "../components/clock/Clock"
import { TreatmentToolbar } from "../components/treatment-toolbar/TreatmentToolbar"

import { ConfirmDialog } from "primereact/confirmdialog"

export const AuthOutlet = () => (
  <div id='AuthOutletId' className='AuthOutlet'>
    <div className='AuthOutlet__Header'>
      <AppSidebar />
      <div className='flex w-full align-items-center justify-content-center '>
        <TreatmentToolbar />
      </div>
      <Clock />
    </div>

    <div className='AuthOutlet__Main'>
      <Outlet />
      <ConfirmDialog appendTo={document.getElementById("AuthOutletId")} />
    </div>
    <div className='AuthOutlet__Footer'>
      <AppFooter />
    </div>
  </div>
)
