import React, { useRef } from "react"
import { OverlayPanel } from "primereact/overlaypanel"
import { Panel } from "primereact/panel"

import { useUserStore } from "../../store/useUserStore"
import { useCurrentSessionStore } from "../../store/useCurrentSessionStore"

import { Button } from "primereact/button"

export const QuickButtons = () => {
  const op = useRef()
  const addQuickBtnOutput = useCurrentSessionStore(
    (state) => state.addQuickBtnOutput
  )
  const { quickButtons } = useUserStore((state) => state.userSettings)
  const handleQuickClick = (output) => {
    addQuickBtnOutput(output)
    op.current.hide()
  }
  return (
    <>
      <Button
        label='Quick'
        icon='pi pi-angle-down'
        iconPos='left'
        className='p-button-sm'
        disabled={!quickButtons}
        onClick={(e) => op.current.show(e)}
      />
      <OverlayPanel ref={op}>
        <Panel header='Quick Buttons'>
          <div
            style={{ maxHeight: "400px" }}
            className='flex w-full flex-column overflow-y-auto p-1 border-round'>
            {quickButtons &&
              quickButtons.map(({ label, key, output }) => (
                <Button
                  className='mb-1 p-button-sm'
                  label={label}
                  key={key}
                  onClick={() => handleQuickClick(output)}
                />
              ))}
          </div>
        </Panel>
      </OverlayPanel>
    </>
  )
}
