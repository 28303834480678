import { create } from "zustand"

import truncate from "lodash/truncate"

export const useGlobalToastStore = create((set) => ({
  toast: null,
  setToast: ({ severity, summary, detail }) =>
    set({
      toast: {
        severity,
        summary,
        life: 3000,
        detail: detail
          ? `${truncate(detail, {
              length: 120,
            })}`
          : "",
      },
    }),
  setSuccess: (message) =>
    set({
      toast: {
        severity: "success",
        life: 3000,
        summary: "Success!",
        detail: message ? `${message}` : "",
      },
    }),
  setError: (message) =>
    set({
      toast: {
        severity: "error",
        life: 3000,
        detail: message
          ? `${truncate(message, {
              length: 120,
            })}`
          : "",
        summary: "Error",
      },
    }),
  resetToastStore: () => set({ toast: null }),
}))
