import { create } from "zustand"

import produce from "immer"
import { SYMPTOM_ITEM, TEST_ITEM } from "../constants/constants"

export const useConditionsSelectStore = create((set, get) => ({
  selectedNode: null,
  isVisible: false,
  conditionNote: "",
  symptomPresent: null,
  testChecked: "",
  testObservations: {},
  selectedObs: {},
  getConditionNote: () => {
    const {
      conditionNote,
      selectedNode,
      selectedObs,
      testChecked,
      symptomPresent,
    } = get()
    const noteType = selectedNode.type
    const value = selectedNode.value
    const note = conditionNote ? `\nNote: ${conditionNote}` : ""
    set({
      conditionNote: "",
      symptomPresent: null,
      testChecked: "",
      testObservations: {},
      selectedObs: {},
    })
    if (noteType === TEST_ITEM) {
      const result = testChecked ? `\n${testChecked}` : ""
      const obs =
        Object.values(selectedObs).length > 0
          ? Object.values(selectedObs)
              .map((o) => `\nObservation: ${o.label}\nResult: ${o.result}`)
              .toString()
          : ""
      const text = `Test: ${value}${result}${obs}${note}`
      return text
    }
    if (noteType === SYMPTOM_ITEM) {
      const present = symptomPresent ? `\nResult: ${symptomPresent}` : ""
      const text = `Symptom: ${value}:${present}${note}`
      return text
    }
  },
  setTestChecked: (payload) => {
    const prev = get().testChecked
    if (prev === payload) {
      set({ testChecked: "" })
    } else {
      set({ testChecked: payload })
    }
  },
  addCheckedObservation: ({ id, result, observation }) => {
    if (!id || !result || !observation) return
    set((state) => {
      state.testObservations[id] = {
        result,
        observation,
      }
    })
  },
  removeCheckedObservation: ({ id }) => {
    set((state) => {
      delete state.testObservations[id]
    })
  },
  onSelectObsChange: ({ id, result, ob }) => {
    const { selectedObs } = get()
    if (selectedObs[id]) {
      if (result == null) {
        set(
          produce((state) => {
            delete state.selectedObs[id]
          })
        )
      } else {
        set(
          produce((state) => {
            state.selectedObs[id] = {
              result,
            }
          })
        )
      }
    } else {
      set(
        produce((state) => {
          state.selectedObs[id] = {
            ...ob,
            result,
          }
        })
      )
    }
  },
  setSymptomPresent: (payload) => {
    const present = get().symptomPresent
    if (present === payload) {
      set({ symptomPresent: null })
    } else {
      set({ symptomPresent: payload })
    }
  },
  clearVideoUrl: () => set({ selectedNode: null }),
  onConditionNoteChange: (text) => set({ conditionNote: text }),
  setSelectedNode: (payload) => {
    const { selectedNode } = get()
    if (selectedNode === payload) {
      set({
        selectedNode: null,
        conditionNote: "",
        symptomPresent: null,
        testChecked: "",
        testObservations: {},
        selectedObs: {},
      })
    } else {
      set({
        selectedNode: payload,
        conditionNote: "",
        symptomPresent: null,
        testChecked: "",
        testObservations: {},
        selectedObs: {},
      })
    }
  },
  setIsVisible: (payload) => set({ isVisible: payload }),
  hideConditionsSelect: () => {
    set({})
  },
  onHideConditionsSelectStore: () => {
    set({ selectedNode: null, isVisible: false, conditionNote: "" })
  },
  clearConditionsSelect: () =>
    set({
      selectedNode: null,
      conditionNote: "",
      testChecked: "",
      testObservations: {},
      selectedObs: {},
      symptomPresent: null,
    }),
  resetConditionsSelectStore: () =>
    set({
      selectedNode: null,
      isVisible: false,
      conditionNote: "",
      symptomPresent: null,
      testChecked: "",
      testObservations: {},
      selectedObs: {},
    }),
}))
