import { create } from "zustand"

import endsWith from "lodash/endsWith"
import words from "lodash/words"

import truncate from "lodash/truncate"
import produce from "immer"
import {
  GENERAL_AREAS_MODE,
  MALE_MODEL_SEX,
  PIN_MODE,
  POSTURE_ASSESSMENT_MODE,
  SELECT_MODE,
} from "../constants/constants"
import { useUserStore } from "./useUserStore"
import { generateFirestoreId } from "../utils/nanoidUtils"
import {
  firestoreGetClientDoc,
  firestoreGetImagesFromTreatment,
  firestoreSaveSessionToFirestore,
  firestoreSetNewClientDoc,
} from "../firebase/firestore"
import { useGlobalToastStore } from "./useGlobalToastStore"
import { useImageStore } from "./useImageStore"
import { useCameraStore } from "./useCameraStore"
import { useMeshVisibilityStore } from "./useMeshVisibilityStore"
import { usePostureStore } from "./usePostureStore"
import { usePinPointStore } from "./usePinPointStore"

import { useConditionsSelectStore } from "./useConditionsSelectStore"
import { useSelectAreaStore } from "./useSelectAreaStore"
import { useRangeOfMovementStore } from "./useRangeOfMovementStore"
import { useSelectedMeshStore } from "./useSelectedMeshStore"

//TODO: Persist useCurrentSessionStore data using persist, createJSONStorage 'zustand/middleware'

export const useCurrentSessionStore = create((set, get) => ({
  sessionDateTime: {
    date: null,
    time: null,
    dateTime: null,
  },
  sessionId: null,
  createdAt: null,
  modelSex: null,
  sessionClient: null,
  lastModified: null,
  sessionNotes: {
    tabOne: "",
    tabTwo: "",
    tabThree: "",
    tabFour: "",
    tabFive: "",
    tabSix: "",
  },
  currentNote: {
    uid: null,
    noteId: null,
    orderIndex: null,
    label: null,
    postureTab: null,
    docId: null,
    id: null,
    abbr: null,
    key: null,
  },
  eventMode: SELECT_MODE,
  setModelSex: (payload) => {
    set({
      modelSex: payload,
    })
  },

  addQuestionNote: ({ noteId, note }) => {
    const { sessionNotes } = get()
    const { userNoteSettings } = useUserStore.getState()
    const prevNote = sessionNotes[noteId]
    const newCurrentNote = userNoteSettings.find((n) => n.noteId === noteId)
    if (prevNote.length < 1) {
      set(
        produce((state) => {
          state.sessionNotes[noteId] = `${note}`
          state.currentNote = newCurrentNote
        })
      )
    } else {
      set(
        produce((state) => {
          state.sessionNotes[noteId] = `${prevNote}\n${note}`
          state.currentNote = newCurrentNote
        })
      )
    }
  },
  addTestNote: ({ noteId, output }) => {
    const { sessionNotes } = get()
    const { userNoteSettings } = useUserStore.getState()
    const prevNote = sessionNotes[noteId]
    const newCurrentNote = userNoteSettings.find((n) => n.noteId === noteId)
    if (prevNote.length < 1) {
      set(
        produce((state) => {
          state.sessionNotes[noteId] = `${output}`
          state.currentNote = newCurrentNote
        })
      )
    } else {
      set(
        produce((state) => {
          state.sessionNotes[noteId] = `${prevNote}\n${output}`
          state.currentNote = newCurrentNote
        })
      )
    }
  },
  setSelectedClientDuringTreatment: (client) => {
    useMeshVisibilityStore.getState().resetMeshVisibilityStore()
    useCameraStore.getState().resetCameraStore()
    usePinPointStore.getState().resetPinPointStore()
    if (client) {
      set(
        produce((state) => {
          state.modelSex = client.modelSex
          state.sessionClient = {
            ...client,
          }
        })
      )
    } else {
      set({ sessionClient: null })
    }
  },
  setClientDuringSession: ({ clientDoc }) => {
    set(
      produce((state) => {
        state.modelSex = clientDoc.modelSex
        state.sessionClient = {
          ...clientDoc,
        }
      })
    )
  },
  addQuickBtnOutput: (output) => {
    const { sessionNotes, currentNote } = get()
    const noteId = currentNote?.noteId
    const prevNote = sessionNotes[noteId]
    if (prevNote.length < 1) {
      set(
        produce((state) => {
          state.sessionNotes[noteId] = `${output}`
        })
      )
    } else {
      set(
        produce((state) => {
          state.sessionNotes[noteId] = `${prevNote}\n${output}`
        })
      )
    }
  },
  addTreatmentButtonOutput: ({ eventMode, output, noteId, docId }) => {
    const { userNoteSettings } = useUserStore.getState()
    const { sessionNotes } = get()

    const prevNote = sessionNotes[noteId]
    const newCurrentNote = userNoteSettings.find((n) => n.noteId === noteId)
    if (prevNote?.length < 1) {
      set(
        produce((state) => {
          state.sessionNotes[noteId] = `${output}`
          state.currentNote = newCurrentNote
          state.eventMode = eventMode
        })
      )
    } else {
      set(
        produce((state) => {
          state.sessionNotes[noteId] = `${prevNote}\n${output}`
          state.currentNote = newCurrentNote
          state.eventMode = eventMode
        })
      )
    }
    if (eventMode === PIN_MODE) {
      usePinPointStore.getState().setCurrentPinId(docId)
      useMeshVisibilityStore
        .getState()
        .setSystemVisibility({ system: "visibleSkin", visibleState: false })
    }

    if (eventMode === GENERAL_AREAS_MODE) {
      useMeshVisibilityStore
        .getState()
        .setSystemVisibility({ system: "visibleSkin", visibleState: true })
    }
  },
  addGenericNoteToTab: ({ noteId, noteText }) => {
    const { sessionNotes } = get()
    const { userNoteSettings } = useUserStore.getState()
    const prevNote = sessionNotes[noteId]
    const newCurrentNote = userNoteSettings.find((n) => n.noteId === noteId)
    if (prevNote.length < 1) {
      set(
        produce((state) => {
          state.sessionNotes[noteId] = `${noteText}`
          state.currentNote = newCurrentNote
        })
      )
    } else {
      set(
        produce((state) => {
          state.sessionNotes[noteId] = `${prevNote}\n${noteText}`
          state.currentNote = newCurrentNote
        })
      )
    }
  },
  addSelectedName: (name) => {
    const {
      sessionNotes,
      currentNote: { noteId },
    } = get()
    const prevNote = sessionNotes[noteId]
    if (prevNote.length < 1) {
      set(
        produce((state) => {
          state["sessionNotes"][noteId] = `${name},`
        })
      )
    } else if (endsWith(prevNote, `${name},`)) {
      return
    } else {
      set(
        produce((state) => {
          state["sessionNotes"][noteId] = `${prevNote} ${name},`
        })
      )
    }
  },

  startNewClientSession: async ({ firstName, lastName, modelSex }) => {
    const { userNoteSettings } = useUserStore.getState()
    const currentNote = userNoteSettings.find((n) => n.orderIndex < 1)
    const { setSuccess, setError } = useGlobalToastStore.getState()
    const newClientId = await firestoreSetNewClientDoc({
      firstName,
      lastName,
      modelSex,
    })
    // console.log(newClient)
    const newClientDoc = await firestoreGetClientDoc(newClientId)
    if (newClientId && newClientDoc) {
      setSuccess("New Client Saved!")
      const sessionId = generateFirestoreId()
      const createdAt = new Date()
      const lastModified = createdAt
      const modelSex = newClientDoc.data()?.modelSex ?? MALE_MODEL_SEX
      set(
        produce((state) => {
          state.sessionId = sessionId
          state.createdAt = createdAt
          state.modelSex = modelSex
          state.currentNote = currentNote
          state.lastModified = lastModified
          state.sessionClient = {
            ...newClientDoc.data(),
          }
        })
      )
    } else {
      setError("Error Creating Client")
    }
  },
  startNewQuickSession: () => {
    const { userNoteSettings } = useUserStore.getState()
    const current = userNoteSettings.find((n) => n.orderIndex < 1)
    const sessionId = generateFirestoreId()
    const createdAt = new Date()
    const lastModified = createdAt

    // const sessionId = generateId()
    set(
      produce((state) => {
        state.sessionId = sessionId
        state.currentNote = current
        state.createdAt = createdAt
        state.lastModified = lastModified
      })
    )
  },
  startExistingClientSession: ({ client }) => {
    const { userNoteSettings } = useUserStore.getState()
    const current = userNoteSettings.find((n) => n.orderIndex < 1)
    const sessionId = generateFirestoreId()
    const createdAt = new Date()
    const lastModified = createdAt

    const modelSex = client?.modelSex ?? MALE_MODEL_SEX
    set(
      produce((state) => {
        state.currentNote = current
        state.createdAt = createdAt
        state.sessionClient = client
        state.sessionId = sessionId
        state.createdAt = createdAt
        state.lastModified = lastModified

        state.modelSex = modelSex
      })
    )
  },
  setInitialCurrentNote: (currentNote) => {
    set({ currentNote })
  },
  onCurrentSessionNoteChange: ({ name, value }) => {
    set(
      produce((state) => {
        state.sessionNotes[name] = value
      })
    )
  },
  handleDraftStart: async (draftInfo) => {
    const { userNoteSettings, sessionDateTime } = draftInfo
    try {
      const images = await firestoreGetImagesFromTreatment(draftInfo.sessionId)
      if (!images.empty) {
        const data = images.docs.map((doc) => ({ ...doc.data() }))
        useImageStore.getState().setImages(data)
      }
    } catch (error) {
      console.log(error)
    }

    const newDateTime = {
      date: sessionDateTime?.date ? sessionDateTime.date.toDate() : null,
      time: sessionDateTime?.time ? sessionDateTime.time.toDate() : null,
      dateTime: sessionDateTime.dateTime,
    }
    const firstNote = userNoteSettings.find((n) => n.orderIndex < 1)
    const newCurrentNote = useUserStore
      .getState()
      .userNoteSettings.find((n) => n.noteId === firstNote.noteId)
    set({
      ...draftInfo,
      sessionDateTime: newDateTime,
      currentNote: newCurrentNote,
    })
  },

  setCurrentNote: (note) => {
    const { eventMode } = get()

    if (note?.postureTab) {
      set(
        produce((state) => {
          state.eventMode = POSTURE_ASSESSMENT_MODE
          state["currentNote"] = note
        })
      )
    } else if (eventMode === POSTURE_ASSESSMENT_MODE) {
      set(
        produce((state) => {
          state.eventMode = SELECT_MODE
          state["currentNote"] = note
        })
      )
    } else {
      set(
        produce((state) => {
          state["currentNote"] = note
        })
      )
    }
  },

  undoLast: (noteId) => {
    const { sessionNotes } = get()
    const prevNote = sessionNotes[noteId]

    if (prevNote.length > 0) {
      if (prevNote.endsWith(",")) {
        const w = words(prevNote, /[^,]+/g)

        const last = w[w.length - 1]
        const newNote = prevNote.replace(`${last},`, "")

        set(
          produce((state) => {
            state.sessionNotes[noteId] = `${newNote}`
          })
        )
      } else {
        const w = words(prevNote)
        const prevNoteLength = prevNote.length
        const last = w[w.length - 1]
        const lastLength = last.length + 1
        const tLength = prevNoteLength - lastLength
        const newNote = truncate(prevNote, {
          length: tLength,
          omission: "",
        })

        set(
          produce((state) => {
            state.sessionNotes[noteId] = `${newNote}`
          })
        )
      }
    }
  },

  setEventMode: (eventMode) => {
    if (eventMode === PIN_MODE) {
      useMeshVisibilityStore
        .getState()
        .setSystemVisibility({ system: "visibleSkin", visibleState: false })
    }
    if (eventMode === GENERAL_AREAS_MODE) {
      useMeshVisibilityStore
        .getState()
        .setSystemVisibility({ system: "visibleSkin", visibleState: true })
    }
    useSelectedMeshStore.getState().clearSelectedMesh()
    set({ eventMode })
  },
  setPinMode: () => {
    useMeshVisibilityStore
      .getState()
      .setSystemVisibility({ system: "visibleSkin", visibleState: true })
    set({ eventMode: PIN_MODE })
  },
  toggleSetEventMode: (mode) => {
    const { eventMode } = get()
    useSelectedMeshStore.getState().clearSelectedMesh()

    if (eventMode === mode) {
      set({ eventMode: SELECT_MODE })
    } else {
      set({
        eventMode: mode,
      })
    }
  },
  saveCurrentSessionAndReset: async ({ isFinal }) => {
    const { setSuccess, setError } = useGlobalToastStore.getState()

    const { userNoteSettings } = useUserStore.getState()
    const { images } = useImageStore.getState()
    const {
      createdAt,
      modelSex,
      sessionId,
      sessionNotes,
      clearAndRestartCurrrentSession,
      sessionDateTime,
      sessionClient,
    } = get()
    const clientId = sessionClient?.clientId ?? null

    const docData = {
      sessionNotes,
      sessionDateTime,
      isFinal,
      createdAt,
      modelSex,
      sessionId,
      sessionClient,
      clientId,
      userNoteSettings,
    }
    try {
      await firestoreSaveSessionToFirestore({
        images,
        docData,
      })

      clearAndRestartCurrrentSession()

      return setSuccess("Saved Treatment Notes!")
    } catch (error) {
      setError(error)
    }
  },
  setCurrentSessionDate: (datestamp) => {
    const date = datestamp
    set(
      produce((state) => {
        state.sessionDateTime.date = date
      })
    )
  },
  setCurrentSessionTime: (time) => {
    const date = time
    set(
      produce((state) => {
        state.sessionDateTime.time = date
      })
    )
  },
  setCurrentSessionDateTime: (time) => {
    const date = time
    set(
      produce((state) => {
        state.sessionDateTime.dateTime = date
        state.sessionDateTime.time = date
        state.sessionDateTime.date = date
      })
    )
  },
  clearCurrentSessionDateTime: () => {
    set({
      sessionDateTime: {
        date: null,
        time: null,
        dateTime: null,
      },
    })
  },
  resetCurrentSession: () => {
    const currentNote =
      useUserStore
        .getState()
        ?.userNoteSettings?.find((n) => n.orderIndex < 1) ?? null
    set({
      sessionDateTime: {
        date: null,
        time: null,
        dateTime: null,
      },
      sessionId: null,
      createdAt: null,
      modelSex: null,
      sessionClient: null,
      lastModified: null,

      sessionNotes: {
        tabOne: "",
        tabTwo: "",
        tabThree: "",
        tabFour: "",
        tabFive: "",
        tabSix: "",
      },
      currentNote,

      eventMode: SELECT_MODE,
    })
  },
  clearAndRestartCurrrentSession: () => {
    const { resetCurrentSession } = get()
    useMeshVisibilityStore.getState().resetMeshVisibilityStore()
    useImageStore.getState().resetImageStore()
    useSelectedMeshStore.getState().clearSelectedMesh()
    usePostureStore.getState().resetPostureAssessmentStore()
    useCameraStore.getState().resetCameraStore()
    useRangeOfMovementStore.getState().resetRom()
    useConditionsSelectStore.getState().resetConditionsSelectStore()
    useSelectAreaStore.getState().resetAreaSelectStore()
    usePinPointStore.getState().resetPinPointStore()
    resetCurrentSession()
  },
}))
