import { create } from "zustand"
import { nanoid } from "nanoid"
import produce from "immer"

export const useMeshVisibilityStore = create((set, get) => ({
  /* ---- Hide/Reveal Meshes ---- */
  hiddenMeshes: [],
  hideMesh: null,
  revealMesh: null,
  revealAllMeshesList: null,
  systems: {
    visibleSkin: true,
    visibleMuscles: true,
    visibleLymphaticSystem: false,
    visibleNervousSystem: false,
    visibleArteries: false,
    visibleVeins: false,
    visibleConnectiveTissue: false,
    visibleJoints: false,
  },
  revealAllHiddenMeshes: () => {
    const { hiddenMeshes } = get()
    set({
      revealAllMeshesList: hiddenMeshes,
      hiddenMeshes: [],
      hideMesh: null,
    })
  },
  revealHiddenMesh: (name) => {
    set((prevState) => ({
      hiddenMeshes: prevState.hiddenMeshes.filter((x) => x.name !== name),
      hideMesh: null,
      revealMesh: name,
    }))
  },
  addHiddenMesh: ({ name }) => {
    const id = nanoid(8)
    set((prevState) => ({
      hideMesh: name,
      hiddenMeshes: [...prevState.hiddenMeshes, { name, id }],
    }))
  },

  setVisibleJoints: (payload) =>
    set(
      produce((state) => {
        state.systems.visibleJoints = payload
      })
    ),

  setSystemVisibility: ({ system, visibleState }) => {
    set(
      produce((state) => {
        state.systems[system] = visibleState
      })
    )
  },
  setSystemVisibilityToggle: (payload) => {
    // const prevState = get().systems?.[payload]
    set(
      produce((prevState) => {
        prevState.systems[payload] = !prevState.systems[payload]
      })
    )
  },

  resetMeshVisibilityStore: () =>
    set({
      hiddenMeshes: [],
      hideMesh: null,
      revealMesh: null,
      revealAllMeshesList: null,
      systems: {
        visibleSkin: true,
        visibleMuscles: true,
        visibleLymphaticSystem: false,
        visibleNervousSystem: false,
        visibleArteries: false,
        visibleVeins: false,
        visibleConnectiveTissue: false,
        visibleJoints: false,
      },
    }),
}))
