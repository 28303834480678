import React, { useRef, useState } from "react"
import { OverlayPanel } from "primereact/overlaypanel"
import { Panel } from "primereact/panel"

import { useUserStore } from "../../store/useUserStore"
import { Button } from "primereact/button"
import { useCurrentSessionStore } from "../../store/useCurrentSessionStore"

export const TreatmentButtons = () => {
  const op = useRef()
  const opTwo = useRef()
  const { treatmentButtons } = useUserStore((state) => state.userSettings)

  const userNoteSettings = useUserStore((state) => state.userNoteSettings)
  const addTreatmentButtonOutput = useCurrentSessionStore(
    (state) => state.addTreatmentButtonOutput
  )

  const [selected, setSelected] = useState(null)

  const handleTreatmentBtn = (e, tb) => {
    if (!tb?.noteId) {
      setSelected(tb)
      opTwo.current.show(e)
    }
    if (tb?.noteId) {
      const tabExists = userNoteSettings.some((x) => x.noteId === tb.noteId)
      if (!tabExists) {
        setSelected(tb)
        opTwo.current.show(e)
        return
      } else {
        const { eventMode, noteId, output, docId } = tb
        addTreatmentButtonOutput({ eventMode, noteId, output, docId })
        opTwo.current.hide()
        op.current.hide()
      }
    }
  }
  const handleConfirmOutputId = (noteId) => {
    const { eventMode, output, docId } = selected
    addTreatmentButtonOutput({ eventMode, noteId, output, docId })
    opTwo.current.hide()
    op.current.hide()
    setSelected(null)
  }

  const onHide = () => {
    opTwo.current.hide()
    op.current.hide()
    setSelected(null)
  }

  return (
    <>
      <Button
        label='Treatment'
        icon='pi pi-angle-down'
        className='p-button-sm'
        iconPos='left'
        onClick={(e) => {
          op.current.toggle(e)
        }}
      />
      <OverlayPanel onHide={onHide} ref={op}>
        <Panel className='CustomPanel' header='Treatment Buttons'>
          <div
            style={{ width: "200px", maxHeight: "50vh" }}
            className='h-full w-full overflow-y-auto  p-3 surface-200'>
            <div className='h-full w-full '>
              {treatmentButtons &&
                treatmentButtons.map((tb) => (
                  <div className='w-full' key={tb.key}>
                    <Button
                      style={{
                        color: selected?.key === tb.key ? "yellow" : "white",
                      }}
                      label={tb.label}
                      onClick={(e) => {
                        handleTreatmentBtn(e, tb)
                      }}
                      className='my-1 w-full p-button-sm'
                    />
                  </div>
                ))}
            </div>
          </div>
        </Panel>
        <OverlayPanel appendTo={document.body} ref={opTwo}>
          <Panel header='Note Output:'>
            <div
              style={{ width: "200px", maxHeight: "360px" }}
              className='flex flex-column justify-content-center p-1 border-1 border-round surface-border surface-overlay '>
              <div className='flex h-full flex-column p-1 overflow-y-auto justify-content-start '>
                {userNoteSettings &&
                  userNoteSettings.map((n) => (
                    <Button
                      className='my-1 p-button-sm'
                      label={n.abbr}
                      key={n.id}
                      onClick={() => handleConfirmOutputId(n?.noteId)}
                    />
                  ))}
              </div>
            </div>
          </Panel>
        </OverlayPanel>
      </OverlayPanel>
    </>
  )
}
