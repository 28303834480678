import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

import "primereact/resources/themes/lara-light-teal/theme.css"
import "primereact/resources/primereact.min.css" //core css
import "primeicons/primeicons.css" //icons

import "./index.scss"

import App from "./App"

import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// firebase deploy --only hosting:TARGET_NAME
// firebase deploy --only hosting:main
// firebase deploy --only hosting:test

serviceWorkerRegistration.register()
