import { create } from "zustand"
import { QUESTION, QUICK_BTN, TREATMENT_BTN } from "../constants/constants"

import sortOrder from "lodash/orderBy"

import { useCurrentSessionStore } from "./useCurrentSessionStore"

export const useUserStore = create((set, get) => ({
  currentUser: null,
  userSettings: {
    treatmentButtons: null,
    quickButtons: null,
    customQuestions: null,
  },
  userNoteSettings: null,
  userClients: null,
  userDrafts: null,
  setCurrentUser: (payload) => {
    if (payload) {
      set({ currentUser: payload })
    } else {
      set({
        currentUser: null,
        userNoteSettings: null,
        userDrafts: null,
        userClients: null,
        userSettings: {
          treatmentButtons: null,
          quickButtons: null,
          customQuestions: null,
        },
      })
    }
  },
  setUserNoteSettings: (payload) => {
    const prevNoteSettings = get().userNoteSettings
    if (!prevNoteSettings) {
      const currentNote = payload?.find((n) => n.orderIndex < 1)
      useCurrentSessionStore.getState().setInitialCurrentNote(currentNote)
    }
    set({
      userNoteSettings: payload,
    })
  },
  setUserSettings: (payload) => {
    const treatmentButtons =
      sortOrder(
        payload.filter((s) => s.settingType === TREATMENT_BTN),
        "label",
        "asc"
      ) || null
    const quickButtons =
      sortOrder(
        payload.filter((s) => s.settingType === QUICK_BTN, "label", "asc")
      ) || null
    const customQuestions =
      sortOrder(
        payload.filter((s) => s.settingType === QUESTION),
        "question",
        "asc"
      ) || null

    set({
      userSettings: {
        treatmentButtons,
        quickButtons,
        customQuestions,
      },
    })
  },
  clearUserSettings: () => {
    set({
      userNoteSettings: null,
      userSettings: {
        treatmentButtons: null,
        quickButtons: null,
        customQuestions: null,
      },
    })
  },
  setUserClients: (payload) =>
    set({
      userClients: payload,
    }),
  setUserDrafts: (payload) =>
    set({
      userDrafts: payload,
    }),
}))
