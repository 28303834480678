import React, { useEffect } from "react"
import PrimeReact from "primereact/api"
import { useUserStore } from "./store/useUserStore"
import { onAuthStateChangedListener } from "./firebase/fireauth"
import { GlobalToast } from "./components/global-toast/GlobalToast"
import Router from "./router/Router"
import {
  firestoreCheckUserDocExists,
  firestoreCreateNewUser,
} from "./firebase/firestore"

/*
  PrimeReact Settings

  These settings ensure Modals, Overlays, Toasts, layer correctly.
  Ex. Toast is masked by "Start New Session Dialog"

*/
//? Ripple is causing scroll blocking violations on touch devices
PrimeReact.ripple = true
PrimeReact.autoZIndex = false
PrimeReact.zIndex = {
  modal: 1500,
  overlay: 1600,
  menu: 1100,
  toast: 1800,
  tooltip: 1050,
}

const App = () => {
  const setCurrentUser = useUserStore((state) => state.setCurrentUser)

  useEffect(() => {
    const unsub = onAuthStateChangedListener(async (userAuth) => {
      try {
        if (userAuth) {
          const userExists = await firestoreCheckUserDocExists(userAuth.uid)

          if (!userExists) {
            await firestoreCreateNewUser({
              uid: userAuth.uid,
              email: userAuth.email,
            })
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        setCurrentUser(userAuth)
      }
    })

    return () => unsub()
  }, [setCurrentUser])

  return (
    <div className='App'>
      <GlobalToast />
      <Router />
    </div>
  )
}

export default App
