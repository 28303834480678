// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
// import { getFunctions } from "firebase/functions"
import { getStorage } from "firebase/storage"
// import { getAnalytics } from "firebase/analytics"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: "burdie-app.firebaseapp.com",
  projectId: "burdie-app",
  storageBucket: "burdie-app.appspot.com",
  messagingSenderId: "728768629102",
  appId: "1:728768629102:web:ca3f25a852717f09f1597f",
  measurementId: "G-Y88E1WGVRG",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const firestore = getFirestore(app)
export const storage = getStorage(app)
// export const functions = getFunctions(app)
