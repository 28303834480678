import { useEffect } from "react"
import { useUserStore } from "../store/useUserStore"
import {
  userClientsOnSnapshot,
  userDraftTreatmentNotesOnSnapshot,
  userNoteSettingsOnSnapshot,
  userSettingsOnSnapshot,
} from "../firebase/firestore"

//TODO: Convert some onSnapshot listeners to regular CRUD operations
/*
  Listens to all user setting docs/collections
*/

export const useUserSettingsOnSnapshot = () => {
  const user = useUserStore((state) => state.currentUser)
  const setUserNoteSettings = useUserStore((state) => state.setUserNoteSettings)
  const setUserSettings = useUserStore((state) => state.setUserSettings)
  const setUserClients = useUserStore((state) => state.setUserClients)
  const setUserDrafts = useUserStore((state) => state.setUserDrafts)

  useEffect(() => {
    if (user) {
      const { uid } = user
      const unsub = userNoteSettingsOnSnapshot({ uid, setUserNoteSettings })

      return () => {
        unsub()
      }
    }
  }, [user, setUserNoteSettings])

  useEffect(() => {
    if (user) {
      const { uid } = user
      const unsub = userDraftTreatmentNotesOnSnapshot({ setUserDrafts, uid })
      return () => unsub()
    }
  }, [user, setUserDrafts])

  useEffect(() => {
    if (user) {
      const { uid } = user
      const unsub = userSettingsOnSnapshot({ uid, setUserSettings })
      return () => unsub()
    }
  }, [user, setUserSettings])

  useEffect(() => {
    if (user) {
      const { uid } = user
      const unsub = userClientsOnSnapshot({ uid, setUserClients })
      return () => unsub()
    }
  }, [user, setUserClients])
  return user
}
