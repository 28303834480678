import { create } from "zustand"
import { RANGE_OF_MOVEMENT_MODE } from "../constants/constants"

import { findSelectedAreaData } from "../data/data"
import { useCurrentSessionStore } from "./useCurrentSessionStore"
import { useRangeOfMovementStore } from "./useRangeOfMovementStore"

export const useSelectAreaStore = create((set, get) => ({
  noteText: "",
  conditionNodes: null,
  testNodes: null,
  jointNodes: null,
  areaNode: null,
  areaOptionMode: null,
  symptomPresent: null,
  testChecked: "",
  areaVideo: null,
  setAreaVideo: (url) =>
    set({
      areaVideo: url,
    }),
  clearAreaVideo: () => set({ areaVideo: null }),
  nodeSelect: () => {
    set({
      noteText: "",
      symptomPresent: null,
      testChecked: "",
    })
  },
  onNoteChange: (text) =>
    set({
      noteText: text,
    }),
  setTestChecked: (payload) => {
    const prev = get().testChecked
    if (prev === payload) {
      set({ testChecked: "" })
    } else {
      set({ testChecked: payload })
    }
  },
  setSymptomPresent: (payload) => {
    const present = get().symptomPresent
    if (present === payload) {
      set({ symptomPresent: null })
    } else {
      set({ symptomPresent: payload })
    }
  },

  setSelectArea: (name) => {
    const a = findSelectedAreaData(name)
    set({
      ...a,
    })
  },
  setAreaOptionMode: (mode) => {
    const prevMode = get().areaOptionMode
    if (prevMode !== mode) {
      set({ areaOptionMode: mode })
    } else {
      set({ areaOptionMode: null })
    }
  },
  setAreaRangeOfMovement: () => {
    const { jointNodes, resetAreaSelectStore, areaNode } = get()

    const { motions, joint } = jointNodes
    const { leftOrRight } = areaNode

    useCurrentSessionStore.getState().setEventMode(RANGE_OF_MOVEMENT_MODE)
    useRangeOfMovementStore.getState().setAreaRomJoint({
      motions,
      pickedJoint: {
        joint,
      },
      jointName: joint,
      leftOrRight,
      areaNode,
    })
    resetAreaSelectStore()
  },
  addAreaSelectNote: (outputNoteId, selected) => {
    const { noteText, testChecked } = get()
    const note = noteText?.length ? `\nNote: ${noteText}` : ""
    const checked = testChecked ? `\nResult: ${testChecked}` : ""
    const formattedNote = `${selected?.label ?? ""}${checked}${note}\n`
    useCurrentSessionStore
      .getState()
      .addGenericNoteToTab({ noteText: formattedNote, noteId: outputNoteId })
    set({
      noteText: "",
    })
  },
  hide: () => {
    set({
      noteText: "",
      testChecked: "",
    })
  },
  clearAreaOptionMode: () =>
    set({
      noteText: "",
      testChecked: "",
      symptomPresent: null,
      areaOptionMode: null,
    }),
  resetAreaSelectStore: () =>
    set({
      noteText: "",
      testChecked: "",
      symptomPresent: null,
      conditionNodes: null,
      testNodes: null,
      jointNodes: null,
      areaNode: null,
      areaOptionMode: null,
    }),
}))
