import dayjs from "dayjs"
// import { format, getTime, toDate } from "date-fns"

import { format, getTime, isValid, toDate } from "date-fns"
import { enAU } from "date-fns/locale"
import localizedFormat from "dayjs/plugin/localizedFormat"

dayjs.extend(localizedFormat)

export const getDayJs = () => {
  return dayjs()
}

export const getClockTime = () => {
  const time = format(new Date(), "E do MMM yyyy pp", { locale: enAU })
  return time
}

export const getCustomTime = (date) => {
  const time = getTime(date)
  return time
}

export const formatCustomDateTime = (dateTime, formatString) => {
  return format(dateTime, formatString, { locale: enAU })
}

export const toValidTime = (dateTime) => {
  if (isValid(dateTime)) {
    const time = format(toDate(dateTime), "p", { locale: enAU })

    if (time) return time
    else {
      return null
    }
  } else {
    return null
  }
}
export const toValidDate = (dateTime) => {
  if (isValid(dateTime)) {
    const date = format(toDate(dateTime), "EEEE do MMM yyyy", {
      locale: enAU,
    })
    if (date) return date
    else {
      return null
    }
  } else {
    return null
  }
}
export const ensureValidDateObject = (date) => {
  if (date?.seconds) {
    return date.toDate()
  }
  if (isValid(new Date(date))) {
    return toDate(new Date(date))
  }
}
