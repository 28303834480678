import React, { useEffect, useState } from "react"
import { getClockTime } from "../../utils/dateTimeUtils"

const Time = () => {
  const [time, setTime] = useState(getClockTime())

  useEffect(() => {
    let timer = setTimeout(() => {
      const now = getClockTime()
      setTime(now)
    }, 1000)

    return () => clearTimeout(timer)
  }, [time])

  return time
}

export const Clock = () => (
  <div className='flex align-items-center justify-content-end'>
    <div className='ClockText'>
      <Time />
    </div>
  </div>
)
