import { customAlphabet, nanoid } from "nanoid"
import { ALPHABET } from "../constants/constants"

const customNanoid = customAlphabet(ALPHABET, 14)

export const generateFirestoreId = () => {
  const firstId = customNanoid()
  const secondId = customNanoid()
  return `${firstId}${secondId}`
}

export const generateNanoidId = () => {
  const id = nanoid()
  return id
}

export const generateSmallId = () => {
  const smlId = nanoid(8)
  return smlId
}

export const generateTinyId = () => {
  const tinyId = nanoid(4)
  return tinyId
}
