import { create } from "zustand"
import drop from "lodash/drop"

import { generateSmallId } from "../utils/nanoidUtils"

import produce from "immer"

export const usePinPointStore = create((set, get) => ({
  pins: [],
  points: [],
  currentPinId: null,
  currentPinColor: "yellow",
  setCurrentPinId: (docId) => {
    set({ currentPinId: docId })
  },
  addPoint: (point) => {
    const id = generateSmallId()
    set(
      produce((state) => {
        state.points.push({ id, point })
      })
    )
  },
  removePoint: () =>
    set(
      produce((state) => ({
        points: drop(state.points),
      }))
    ),
  resetPoints: () => {
    set((state) => ({
      points: drop([...state.points]),
    }))
  },
  // addPin: ({ point }) => {
  //   const id = generateSmallId()
  //   const key = generateSmallId()
  //   set((state) => ({
  //     pins: [...state.pins, { point, id, key }],
  //   }))
  // },
  addPin: (point) => {
    const { currentPinId, currentPinColor } = get()
    const key = generateSmallId()
    set(
      produce((state) => {
        state.pins.push({
          point,
          pinId: currentPinId,
          pinColor: currentPinColor,
          key,
        })
      })
    )
  },
  resetPinPointStore: () => {
    set({
      pins: [],
      points: [],
      currentPinId: null,
      currentPinColor: "yellow",
    })
  },
}))
