import { create } from "zustand"

import produce from "immer"
import { generateSmallId, generateTinyId } from "../utils/nanoidUtils"

import { useDimStore } from "./useDimStore"
import { useCurrentSessionStore } from "./useCurrentSessionStore"
// import {
//   firebaseStorageDeleteRemovedImage,
//   firebaseStorageSaveImg,
// } from "../firebase/storage"

export const useImageStore = create((set, get) => ({
  images: [],
  r3fDrawingBufferListener: null,
  archivedImages: [],
  triggerDrawingBufferDataURL: () => {
    const id = generateTinyId()
    set({ r3fDrawingBufferListener: id })
  },
  addImage: ({ imgSrc, imgType }) => {
    const { width, height } = useDimStore.getState().dims

    const sessionId = useCurrentSessionStore.getState().sessionId
    const alt = generateSmallId()
    const id = generateSmallId()
    const key = generateSmallId()
    // const img = await firebaseStorageSaveImg({ id, imgSrc, sessionId })
    set(
      produce((prevState) => {
        prevState.images.push({
          imgSrc,
          alt,
          id,
          key,
          imgType,
          sessionId,
          dims: {
            width,
            height,
          },
        })
      })
    )
  },
  setImages: (imgs) => {
    set({ images: imgs })
  },
  removeImage: (i) => {
    // const { images } = get()
    // const undo = images.find((img) => img.id === id)
    // console.log(undo)
    // console.log(images)
    // await firebaseStorageDeleteRemovedImage({ id, sessionId })
    set(
      produce((prevState) => ({
        // prevState.images.map((img) => img.id !== id ? img : return)
        images: prevState.images.filter((img) => img.id !== i.id),
        // prevState.archivedImages.concat(undo)
      }))
    )
  },
  resetImageStore: () =>
    set({ images: [], r3fDrawingBufferListener: null, archivedImages: [] }),
}))
