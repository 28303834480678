// RegEx
export const searchTerms = /left|right|_|anterior|posterior|Joint/gi
export const spine = /spine/gi
export const underScore = /_/gi

// Nanoid Alphabet
export const ALPHABET =
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

// Note Tabs
export const TAB_ONE = "tabOne"
export const TAB_TWO = "tabTwo"
export const TAB_THREE = "tabThree"
export const TAB_FOUR = "tabFour"
export const TAB_FIVE = "tabFive"
export const TAB_SIX = "tabSix"

// Visible Systems
export const VISIBLE_SKIN = "visibleSkin"
export const VISIBLE_MUSCLES = "visibleMuscles"
export const VISIBLE_LYMPHATIC_SYSTEM = "visibleLymphaticSystem"
export const VISIBLE_NERVOUS_SYSTEM = "visibleNervousSystem"
export const VISIBLE_ARTERIES = "visibleArteries"
export const VISIBLE_VEINS = "visibleVeins"
export const VISIBLE_CONNECTIVE_TISSUE = "visibleConnectiveTissue"
export const VISIBLE_JOINTS = "visibleJoints"
export const VISIBLE_GENERAL_AREAS = "visibleGeneralAreas"

// Events
export const POSTURE_ASSESSMENT_MODE = "posture-assessment-mode"
export const DISSECT_MODE = "dissect-mode"
export const SPECIFIC_MODE = "specific-mode"
export const GENERAL_AREAS_MODE = "general-areas-mode"
export const SELECT_MODE = "select-mode"
export const POINT_MODE = "point-mode"
export const RANGE_OF_MOVEMENT_MODE = "range-of-movement-mode"
export const TAP_MODE = "tap-mode"
export const DRAW_MODE = "draw-mode"
export const PIN_MODE = "pin-mode"
export const DEFAULT_SELECT_MODE = "default-select-mode"
export const TRP_MODE = "trigger-point-mode"
export const AREA_TEST_MODE = "area-test-mode"
export const AREA_MODE = "area-mode"
// Condition Tree Node Types
export const TEST_ITEM = "test-item"
export const SYMPTOM_ITEM = "symptom-item"
export const OBSERVATION_ITEM = "observation-item"
export const TEST_DESCRIPTION_ITEM = "test-description-item"
export const OTHER_INFO_ITEM = "other-info-item"
export const MECH_OF_INJURY_ITEM = "mechanism-of-injury-item"
export const CONDITION_ITEM = "condition-item"
export const TEST_LABEL = "test-label"
export const SYMPTOM_LABEL = "symptom-label"
export const MECH_OF_INJURY_LABEL = "mechanism-of-injury-label"
export const OTHER_INFO_LABEL = "other-info-label"
export const OBSERVATION_LABEL = "obs-label"

// Model Sex
export const MALE_MODEL_SEX = "Male"
export const FEMALE_MODEL_SEX = "Female"

// Answer Types
export const TEXTAREA = "textarea"
export const CHECKBOX = "checkbox"
export const RADIO = "radio"

export const CREATE_NEW_QUESTION = "create-new-question"
export const MODIFY_QUESTION = "modify-question"
export const CREATE_QUICK_BUTTON = "create-quick-button"
export const CREATE_TREATMENT_BUTTON = "create-treatment-button"

export const CUSTOM_TABS = "customTabs"
export const CUSTOM_QUESTIONS = "customQuestions"
export const QUICK_BUTTONS = "quickButtons"
export const TREATMENT_BUTTONS = "treatmentButtons"
export const USER_CLIENTS = "userClients"
export const FAV_TREATMENTS = "favTreatments"

// Image Types
export const R3F_MODEL = "r3f-model"
export const POSTURE_MODEL = "posture-model"

// Tabs
export const CURRENT_TAB = "currentTab"
export const POSTURAL_ASSESSMENT_TAB = "posturalAssessmentTab"

// User Setting Types
export const QUESTION = "question"
export const QUICK_BTN = "quick-btn"
export const TREATMENT_BTN = "treatment-btn"
export const NOTE_TAB = "note-tab"

export const QUICK_BTN_MODIFY = "quick-btn-modify"
export const TREATMENT_BTN_MODIFY = "treatment-btn-modify"
export const QUESTION_MODIFY = "question-modify"
export const NOTE_TAB_MODIFY = "note-tab-modify"

export const QUICK_BTN_NEW = "quick-btn-new"
export const TREATMENT_BTN_NEW = "treatment-btn-new"
export const QUESTION_NEW = "question-new"
export const NOTE_TAB_NEW = "note-tab-new"

export const SESSION_ACTIVE = "session-active"
export const SESSION_PAUSED = "session-paused"

// Severity
export const SUCCESS = "success"
export const INFO = "info"
export const WARNING = "warn"
export const ERROR = "error"

export const ASSESSMENT_ONE = "assOne"
export const ASSESSMENT_TWO = "assTwo"
export const ASSESSMENT_THREE = "assThree"

// Area Select
export const AREA_CONDITIONS = "area-conditions"
export const AREA_QUESTIONS = "area-questions"
export const AREA_TESTS = "area-tests"
// Areas
// "footAnkle",

// "thigh",
// "pelvis",

// Shared Areas
export const CERVICAL_AREA = "cervical-area"
export const THORACIC_AREA = "thoracic-area"
export const LUMBAR_AREA = "lumbar-area"
export const HIP_AREA = "hip-area"
export const SHOULDER_AREA = "shoulder-area"
export const ELBOW_AREA = "elbow-area"

// Condition Areas
export const THIGH_AREA = "thigh-area"
export const PELVIS_AREA = "pelvis-area"
export const FOOT_AREA = "foot-area"

export const ARM_AREA = "arm-area"
// Joint Areas
export const WRIST_AREA = "wrist-area"
export const ANKLE_AREA = "ankle-area"
export const TMJ_AREA = "tmj-area"
export const KNEE_AREA = "knee-area"
export const FOREARM_AREA = "forearm-area"
export const SHARED_AREAS_ARRAY = [
  CERVICAL_AREA,
  THORACIC_AREA,
  LUMBAR_AREA,
  HIP_AREA,
  SHOULDER_AREA,
  ELBOW_AREA,
]
export const ARM_AREA_ARRAY = [FOREARM_AREA, WRIST_AREA, ELBOW_AREA]
export const CONDITION_AREAS_ARRAY = [THIGH_AREA, PELVIS_AREA, FOOT_AREA]
export const JOINT_AREAS_ARRAY = [
  WRIST_AREA,
  ANKLE_AREA,
  TMJ_AREA,
  KNEE_AREA,
  FOREARM_AREA,
]
export const AREAS_ARRAY = [
  ...SHARED_AREAS_ARRAY,
  ...JOINT_AREAS_ARRAY,
  ...CONDITION_AREAS_ARRAY,
]
