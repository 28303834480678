import React from "react"

export const AppFooter = () => (
  <>
    <div className='text-xs font-bold text-center text-900'>
      © Burdie Pty Ltd
    </div>
    <div className='text-xs font-bold text-center text-900'>
      ABN: 39 643 700 850
    </div>
  </>
)
