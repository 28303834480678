import {
  createUserWithEmailAndPassword,
  signOut,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth"

import { auth } from "./firebase"

export const onAuthStateChangedListener = (callback) =>
  onAuthStateChanged(auth, callback)

export const authSignOut = async () => {
  return await signOut(auth)
}
export const authSignIn = async ({ signInEmail, signInPassword }) => {
  return await signInWithEmailAndPassword(auth, signInEmail, signInPassword)
}
export const authCreateNewUser = async ({
  registerEmail,
  registerPassword,
}) => {
  return await createUserWithEmailAndPassword(
    auth,
    registerEmail,
    registerPassword
  )
}
