import { create } from "zustand"

/*
  Some Konva (React-Konva) components require absolute height/width in pixels,
  We wish to display it over the r3f model canvas,
  and leave the note inputs and tabs accessible.
*/

export const useDimStore = create((set) => ({
  dims: {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  },
  onDimChange: (dims) => {
    // const { x, y, width, height } = dims
    set({
      dims,
    })
  },
  resetDimStore: () =>
    set({
      dims: {
        bottom: 0,
        height: 0,
        left: 0,
        right: 0,
        top: 0,
        width: 0,
        x: 0,
        y: 0,
      },
    }),
}))
