import React, { useRef } from "react"

import { QuickButtons } from "../../components/quick-buttons/QuickButtons"
import { TreatmentButtons } from "../../components/treatment-buttons/TreatmentButtons"
import { useCurrentSessionStore } from "../../store/useCurrentSessionStore"
import { useLocation } from "react-router-dom"
import { Button } from "primereact/button"
import { useMeshVisibilityStore } from "../../store/useMeshVisibilityStore"

import { SELECT_MODE, DISSECT_MODE, AREA_MODE } from "../../constants/constants"
import { Panel } from "primereact/panel"
import { OverlayPanel } from "primereact/overlaypanel"

const AreaButton = () => {
  const eventMode = useCurrentSessionStore((state) => state.eventMode)
  const toggleSetEventMode = useCurrentSessionStore(
    (state) => state.toggleSetEventMode
  )
  const handleToggleEvent = () => {
    toggleSetEventMode(AREA_MODE)
  }
  return (
    <Button
      style={{
        color: `${eventMode === AREA_MODE ? "yellow" : "white"}`,
      }}
      value={AREA_MODE}
      icon='pi pi-table'
      label='Area'
      onClick={handleToggleEvent}
      className=' p-button-sm'
    />
  )
}
const SelectButton = () => {
  const eventMode = useCurrentSessionStore((state) => state.eventMode)
  const setEventMode = useCurrentSessionStore((state) => state.setEventMode)

  const handleSelect = (e) => {
    setEventMode(e.currentTarget.value)
  }
  return (
    <Button
      style={{
        color: `${eventMode === SELECT_MODE ? "yellow" : "white"}`,
      }}
      value={SELECT_MODE}
      label='Select'
      onClick={handleSelect}
      icon='pi pi-pencil'
      className=' p-button-sm'
    />
  )
}

const DissectButtons = () => {
  const op = useRef()

  const eventMode = useCurrentSessionStore((state) => state.eventMode)
  const setEventMode = useCurrentSessionStore((state) => state.setEventMode)

  const hiddenMeshes = useMeshVisibilityStore((state) => state.hiddenMeshes)
  const revealHiddenMesh = useMeshVisibilityStore(
    (state) => state.revealHiddenMesh
  )
  const revealAllHiddenMeshes = useMeshVisibilityStore(
    (state) => state.revealAllHiddenMeshes
  )
  const cb = () => {
    const num = useMeshVisibilityStore.getState().hiddenMeshes
    if (num < 1) {
      op.current.hide()
    }
  }

  const handleRevealAll = () => {
    revealAllHiddenMeshes()
    op.current.hide()
  }
  const handleSelect = (e) => {
    setEventMode(e.currentTarget.value)
  }
  const handleCallback = () => {
    cb()
  }

  return (
    <>
      <Button
        label='Dissect'
        value={DISSECT_MODE}
        icon='pi pi-minus-circle'
        onClick={handleSelect}
        className='p-button-sm'
        style={{
          color: `${eventMode === DISSECT_MODE ? "yellow" : "white"}`,
        }}
      />
      <Button
        disabled={hiddenMeshes.length < 1}
        label={`${hiddenMeshes?.length ?? 0}`}
        iconPos='right'
        icon='pi  pi-angle-down'
        className='p-button-danger p-button-sm'
        onClick={(e) => op.current.toggle(e)}
      />
      <OverlayPanel className='HiddenMeshOverlayPanel' ref={op}>
        <Panel className='CustomPanel ' header='Hidden'>
          <div className='flex flex-column  '>
            <div
              style={{ height: "300px", maxHeight: "340px", width: "300px" }}
              className='flex flex-column overflow-y-auto '>
              {hiddenMeshes &&
                hiddenMeshes.map((m) => (
                  <Button
                    // style={{ minHeight: "40px" }}
                    name={m.name}
                    className='my-1 p-button-sm'
                    label={m.name.replace(/_/gi, " ")}
                    key={m.id}
                    onClick={() => {
                      revealHiddenMesh(m.name)
                      handleCallback()
                    }}
                  />
                ))}
            </div>
            <div className='flex align-items-center justify-content-center p-1 mt-1'>
              <Button
                label='Reveal All'
                className='p-button-secondary p-button-sm'
                onClick={handleRevealAll}
              />
            </div>
          </div>
        </Panel>
      </OverlayPanel>
    </>
  )
}

const ToolButtons = () => {
  return (
    <>
      <SelectButton />
      <div className='p-buttonset flex align-items-center'>
        <DissectButtons />
      </div>
      <AreaButton />
    </>
  )
}

const UserButtons = () => {
  return (
    <div className='p-buttonset flex align-items-center'>
      <TreatmentButtons />
      <QuickButtons />
    </div>
  )
}

export const TreatmentToolbar = () => {
  let location = useLocation()

  if (location?.pathname !== "/user/treatment") {
    return null
  }

  return (
    <div className='surface-200 border-round  flex  align-items-center p-1 gap-1 TreatmentToolbar'>
      <ToolButtons />

      <UserButtons />
    </div>
  )
}
