import { create } from "zustand"

import produce from "immer"
import { generateSmallId } from "../utils/nanoidUtils"

const immer = (config) => (set, get, api) =>
  config((fn) => set(produce(fn)), get, api)

export const usePostureStore = create(
  immer((set, get) => ({
    lines: [],
    currentLine: [],
    brushColor: "b51bb5",
    tool: "pen",
    brushSize: 5,
    assessments: {
      assOne: [],
      assTwo: [],
      assThree: [],
    },
    currentAssessment: "assOne",
    postureListener: null,
    triggerPostureListener: () => {
      const id = generateSmallId()
      set(() => ({
        postureListener: id,
      }))
    },
    setCurrentAssessment: (payload) =>
      set(() => ({ currentAssessment: payload })),
    addLineAndClearCurrent: () => {
      const { currentLine, brushSize, brushColor, currentAssessment } = get()
      const id = generateSmallId()
      set((state) => {
        void state.assessments[currentAssessment].push({
          id,
          points: currentLine,
          stroke: `#${brushColor}`,
          strokeWidth: brushSize,
        })
      })
      set(() => ({ currentLine: [] }))
    },
    undoLast: () => {
      const { currentAssessment } = get()
      set((state) => void state.assessments[currentAssessment].pop())
    },
    clearCurrentCanvas: () => {
      const { currentAssessment } = get()
      set((state) => void (state.assessments[currentAssessment] = []))
    },
    addCurrentPoint: (point) => {
      set((state) => void state.currentLine.push(point.x, point.y))
    },
    clearCurrentLine: () => set(() => ({ currentLine: [] })),
    addLine: () => {
      const { currentLine, brushSize, brushColor, currentAssessment } = get()
      const id = generateSmallId
      set((state) => {
        void state.assessments[currentAssessment].push({
          id,
          points: currentLine,
          stroke: `#${brushColor}`,
          strokeWidth: brushSize,
        })
      })
    },
    setTool: (payload) => set(() => ({ tool: payload })),

    setBrushColor: (color) => set(() => ({ brushColor: color })),
    // ({ brushColor: `#${color}` }))
    setBrushSize: (size) => set(() => ({ brushSize: size })),
    resetPostureAssessmentStore: () =>
      set(() => ({
        lines: [],
        currentLine: [],
        brushColor: "b51bb5",
        tool: "pen",
        brushSize: 5,
        assessments: {
          assOne: [],
          assTwo: [],
          assThree: [],
        },
        postureListener: null,

        currentAssessment: "assOne",
      })),
  }))
)
