import React from "react"

export const Spinner = () => {
  return (
    <div className='GlobalSpinner__Container'>
      <div className='Spinner'></div>
    </div>
  )
}

export const GlobalSpinner = () => {
  return (
    <div className='GlobalSpinner__Container'>
      <Spinner />
    </div>
  )
}

export const LocalSpinner = () => {
  return (
    <div className='LocalSpinner__Container'>
      <Spinner />
    </div>
  )
}
