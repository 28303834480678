import { create } from "zustand"
import { searchAndFormatJointName } from "../data/data"
import produce from "immer"

import { useSelectedMeshStore } from "./useSelectedMeshStore"

export const useRangeOfMovementStore = create((set, get) => ({
  romOpen: false,

  pickedJoint: null,
  motions: null,
  motionType: {},
  activePassive: null,
  leftRightBilat: null,
  joint: null,
  jointName: null,
  rawName: null,
  addCurrentRomNote: () => {
    const { activePassive, leftRightBilat, motionType, pickedJoint } = get()
    const j = pickedJoint?.joint ? `${pickedJoint.joint} ` : ""
    const motionNotes = Object.values(motionType)
      .map((motion) =>
        motion.fullRange
          ? ` ${motion.motion}: Full Range of Movement`
          : ` ${motion.motion}: ${motion.rangeValue}°`
      )
      .toString()
    const lrb = leftRightBilat ? `${leftRightBilat} ` : ""
    const ap = activePassive ? `${activePassive}` : ""
    const m = motionNotes ? `${motionNotes} ` : ""
    const noteText = `${lrb}${j}${ap}${m}\n`
    set({
      activePassive: null,
      leftRightBilat: null,
      motionType: {},
      // joint: null,
    })
    return noteText
  },
  setAreaRomJoint: ({ pickedJoint, motions, leftOrRight, jointName }) => {
    set({
      pickedJoint,
      joint: jointName,
      motions,
      leftRightBilat: leftOrRight ?? null,
    })
  },
  setJoint: (jointName) => {
    const { pickedJoint, motions } = searchAndFormatJointName(jointName)
    set({
      pickedJoint,
      joint: jointName,
      motions,
    })
    useSelectedMeshStore.getState().clearSelectedMesh()
  },

  setCleanJoint: (jointName) => {
    const { pickedJoint, motions, leftOrRight } =
      searchAndFormatJointName(jointName)
    set({
      pickedJoint,
      joint: jointName,
      motions,
      leftRightBilat: leftOrRight ?? null,
    })
  },
  setMotionType: ({ motion }) => {
    set(
      produce((state) => {
        if (state.motionType[motion.motionId]) {
          delete state.motionType[motion.motionId]
        } else {
          state.motionType[motion.motionId] = {
            ...motion,
          }
        }
      })
    )
  },
  setRange: ({ rangeValue, id }) =>
    set(
      produce((state) => {
        state.motionType[id]["rangeValue"] = rangeValue
      })
    ),
  setFullRange: (id) => {
    set(
      produce((state) => {
        if (state.motionType[id]?.fullRange) {
          state.motionType[id].fullRange = false
        } else {
          state.motionType[id].fullRange = true
        }
      })
    )
  },
  onRomHide: () => {
    set({
      pickedJoint: null,
      motions: null,
      motionType: {},
      activePassive: null,
      leftRightBilat: null,
      joint: null,
    })
  },
  setActivePassive: (payload) => {
    const { activePassive } = get()
    if (activePassive === payload) {
      set({ activePassive: null })
    } else {
      set({
        activePassive: payload,
      })
    }
  },

  setPickedJoint: ({ pickedJoint, motions }) => {
    set({ pickedJoint, motions })
  },
  onHideRom: () => {
    set({
      pickedJoint: null,
      motions: null,
      motionType: {},
      activePassive: null,
      leftRightBilat: null,
      joint: null,
      // jointName: null,
    })
  },

  setLeftRightBilat: (payload) => {
    const { leftRightBilat } = get()
    if (leftRightBilat === payload) {
      set({ leftRightBilat: null })
    } else {
      set({ leftRightBilat: payload })
    }
  },
  clearRom: () => {
    set({
      motions: null,

      motionType: {},
      activePassive: null,
      leftRightBilat: null,
    })
  },
  setRomOpen: (payload) =>
    set({
      romOpen: payload,
    }),
  resetRom: () =>
    set({
      pickedJoint: null,
      activePassive: null,
      motions: null,
      motionType: {},
      leftRightBilat: null,
      joint: null,
      jointName: null,
      romOpen: false,
    }),
}))
