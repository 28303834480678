import CONDITIONS from "./conditions.json"
import { nanoid } from "nanoid"
import JOINTS from "./joints.json"
import lowerCase from "lodash/lowerCase"
import capitalize from "lodash/capitalize"

import flatten from "lodash/flatten"

import {
  TEST_ITEM,
  // CONDITION_ITEM,
  TEST_DESCRIPTION_ITEM,
  OBSERVATION_ITEM,
  SYMPTOM_ITEM,
  MECH_OF_INJURY_ITEM,
  OTHER_INFO_ITEM,
  TEST_LABEL,
  OBSERVATION_LABEL,
  SYMPTOM_LABEL,
  MECH_OF_INJURY_LABEL,
  OTHER_INFO_LABEL,
} from "../constants/constants"

export const jointNames = [
  "Elbow",
  "Forearm",
  "Wrist",
  "Shoulder",
  "Hip",
  "Knee",
  "Ankle",
  "Cervical Spine",
  "Lumbar Spine",
  "Thoracic Spine",
  "TMJ",
]
const left = /left/gi
const right = /right/gi

const searchTerms = /left|right|_|anterior|posterior|Joint/gi
const spine = /spine/gi

// export const CONDITION_TREE_NODES = CONDITIONS.map((c, idx) => ({
//   label: c.label,
//   value: c.label,
//   data: c,
//   id: c.id,
//   area: c.area,
//   key: `${idx}`,
//   type: CONDITION_ITEM,
//   selectable: false,

//   children: [
//     {
//       label: "Tests",
//       key: `${idx}-0`,
//       data: c.tests,
//       selectable: false,
//       className: `${c.tests.length > 0 ? "" : "hidden"} capitalize`,
//       type: TEST_LABEL,
//       children: c.tests
//         ? c.tests.map((t, idt) => ({
//             label: t.label,
//             value: t.label,
//             className: `${
//               t.label.length > 0 ? "" : "hidden"
//             } capitalize line-height-3`,
//             url: t?.url ?? "",
//             selectable: true,

//             type: TEST_ITEM,
//             id: t.id,
//             conditionId: c.id,
//             key: `${idx}-0-${idt}`,
//             data: t,
//             children: [
//               {
//                 data: t,
//                 label: `Description: ${t.description}`,
//                 value: t.description,
//                 className: `${
//                   t.description.length > 0 ? "" : "hidden"
//                 } capitalize`,
//                 key: `${idx}-0-${idt}-0`,
//                 type: TEST_DESCRIPTION_ITEM,
//                 selectable: false,
//                 // icon: "pi pi-info",
//               },
//               {
//                 label: "Observations",
//                 type: OBSERVATION_LABEL,
//                 value: "Observations",
//                 key: `${idx}-0-${idt}-1`,
//                 data: t.observations,
//                 selectable: false,

//                 className: `${
//                   t.observations.length > 0 ? "" : "hidden"
//                 } capitalize`,
//                 children:
//                   t.observations &&
//                   t.observations.map((o, ido) => ({
//                     label: o.label,
//                     uuid: nanoid(8),
//                     value: o,
//                     id: o.id,
//                     selectable: false,
//                     key: `${idx}-0-${idt}-1-${ido}`,
//                     type: OBSERVATION_ITEM,
//                   })),
//               },
//               {
//                 label: "Watch",
//                 value: t?.url ?? "",
//                 key: `${idx}-0-${idt}-2`,
//                 data: t?.url ?? "",
//                 className: `${t?.url ? "" : "hidden"}`,
//                 selectable: true,
//                 type: "video-link",
//                 icon: t?.url ? "pi pi-youtube" : "",
//               },
//             ],
//           }))
//         : [],
//     },
//     {
//       label: "Symptoms",
//       type: SYMPTOM_LABEL,
//       key: `${idx}-1`,
//       data: c.symptoms,
//       value: "Symptoms",
//       selectable: false,

//       className: `${c.symptoms.length > 0 ? "" : "hidden"} capitalize`,
//       children: c.symptoms
//         ? c.symptoms.map((s, ids) => ({
//             label: s.label,
//             value: s.label,
//             key: `${idx}-1-${ids}`,
//             id: s.id,
//             type: SYMPTOM_ITEM,
//             data: s,
//             selectable: true,
//           }))
//         : [],
//     },
//     {
//       label: "Mechanism Of Injury",
//       selectable: false,
//       type: MECH_OF_INJURY_LABEL,
//       data: c.mechanismOfInjury,
//       value: "Mechanism Of Injury",
//       key: `${idx}-2`,
//       className: `${c.mechanismOfInjury.length > 0 ? "" : "hidden"} capitalize`,
//       children:
//         c.mechanismOfInjury.length > 0 &&
//         c.mechanismOfInjury.map((m, idm) => ({
//           label: m.label,
//           value: m.label,
//           data: m,
//           key: `${idx}-2-${idm}`,
//           id: m.id,
//           type: MECH_OF_INJURY_ITEM,
//           selectable: false,
//         })),
//     },
//     {
//       label: "Other Info",
//       className: `${c.otherInfo.length > 0 ? "" : "hidden"} capitalize`,
//       data: c,
//       selectable: false,
//       type: OTHER_INFO_LABEL,
//       value: "Other Info",
//       key: `${idx}-3`,
//       children:
//         c.otherInfo.length > 0 &&
//         c.otherInfo.map((i, idi) => ({
//           key: `${idx}-3-${idi}`,
//           data: i,
//           label: i.label,
//           value: i.label,
//           id: i.id,
//           type: OTHER_INFO_ITEM,
//           selectable: false,
//           // icon: "pi pi-info",
//         })),
//     },
//   ],
// }))
export const formatConditionNodes = (conditions) => {
  const nodes =
    conditions?.length < 1
      ? null
      : conditions?.map((c, idx) => ({
          key: `${idx}`,
          label: c.label,
          value: c,
        }))

  return nodes
}
export const findSelectedAreaData = (areaName) => {
  const rawAreaName = areaName
  const loweredName = lowerCase(areaName)
  const isLeft = loweredName.match(left)
  const isRight = loweredName.match(right)
  const isLeftOrRight = isLeft || isRight || null
  const areaKey = loweredName
    .replace(searchTerms, " ")
    // .replace(spine, "")
    .trim()
    .replace("  ", "")
    .replace(spine, "")
    .trim()
  const name = areaKey
  const jointNodes = JOINTS.find((j) => j.area === areaKey)
  const conditionNodes = CONDITIONS.filter((c) => areaKey === c.area)
  const testNodes = flatten(conditionNodes.map((c) => c.tests))
  const areaNode = {
    rawName: rawAreaName,
    cleanName: areaName.replace(/_/gi, " "),
    leftOrRight: isLeftOrRight ? capitalize(isLeftOrRight[0]) : null,
    jointName: `${
      isLeftOrRight ? capitalize(isLeftOrRight[0]) : ""
    } ${capitalize(name)}`,
    name: capitalize(name),
  }
  // const n = { jointNodes, testNodes, areaNode }
  return { jointNodes, testNodes, areaNode, conditionNodes }
}
const findIsLeftOrRight = (area) => {
  const formatLower = lowerCase(area)
  const isLeft = formatLower.match(left)
  const isRight = formatLower.match(right)

  if (isLeft) {
    return "Left"
  } else if (isRight) {
    return "Right"
  } else {
    return null
  }
}
export const searchAndFormatJointName = (jointName) => {
  const loweredString = lowerCase(jointName)
  const leftOrRight = findIsLeftOrRight(loweredString)
  const formattedJointName = jointName
    .replace(searchTerms, " ")
    .trim()
    .replace(spine, "")
    .trim()
  const areaKey = lowerCase(formattedJointName)
  const joint = JOINTS.find((j) => j.area === areaKey)
  const jointObj = {
    motions: joint?.motions ?? null,
    pickedJoint: joint ?? null,
    leftOrRight,
    jointArea: joint?.jointArea ?? "",
  }
  // console.log(jointObj)

  return jointObj
}
export const formatTestNodes = (tests) => {
  const nodes =
    tests?.length < 1
      ? null
      : tests.map((c, idx) => ({
          className: "AreaSelectLeafNodeItem capitalize",
          key: `${idx}`,
          label: c.label,
          value: c,
          children:
            c?.observations.length < 1
              ? null
              : [
                  {
                    label: "Observations",
                    selectable: false,
                    key: `${idx}-0`,
                    className: "AreaSelectNodeItem ",
                    children: c?.observations.map((t, odx) => ({
                      className: "AreaSelectLeafNodeItem capitalize",
                      key: `${idx}-0-${odx}`,
                      label: t.label,
                      value: t,
                    })),
                  },
                ],
        }))
  return nodes
}

export const formatAreaSelectConditionNodes = (conditionAreas) => {
  const nodes = conditionAreas.map((c, idx) => ({
    area: c.area,
    key: `${idx}`,
    selectable: false,
    label: `${c.label}`,
    children: [
      {
        label: "Tests",
        key: `${idx}-0`,
        data: c.tests,
        selectable: false,
        className: `${c.tests.length > 0 ? "" : "hidden"} capitalize`,
        type: TEST_LABEL,
        children: c.tests
          ? c.tests.map((t, idt) => ({
              label: t.label,
              value: t.label,
              className: `${
                t.label.length > 0 ? "" : "hidden"
              } capitalize line-height-3`,
              url: t?.url ?? "",
              selectable: true,

              type: TEST_ITEM,
              id: t.id,
              conditionId: c.id,
              key: `${idx}-0-${idt}`,
              data: t,
              children: [
                {
                  data: t,
                  label: `Description: ${t.description}`,
                  value: t.description,
                  className: `${
                    t.description.length > 0 ? "" : "hidden"
                  } capitalize`,
                  key: `${idx}-0-${idt}-0`,
                  type: TEST_DESCRIPTION_ITEM,
                  selectable: false,
                  // icon: "pi pi-info",
                },
                {
                  label: "Observations",
                  type: OBSERVATION_LABEL,
                  value: "Observations",
                  key: `${idx}-0-${idt}-1`,
                  data: t.observations,
                  selectable: false,

                  className: `${
                    t.observations.length > 0 ? "" : "hidden"
                  } capitalize`,
                  children:
                    t.observations &&
                    t.observations.map((o, ido) => ({
                      label: o.label,
                      uuid: nanoid(8),
                      value: o,
                      className: "capitalize",

                      id: o.id,
                      selectable: false,
                      key: `${idx}-0-${idt}-1-${ido}`,
                      type: OBSERVATION_ITEM,
                    })),
                },
                {
                  label: "Watch",
                  value: t?.url ?? "",
                  key: `${idx}-0-${idt}-2`,
                  data: t?.url ?? "",
                  className: `${t?.url ? "" : "hidden"}`,
                  selectable: true,
                  type: "video-link",
                  icon: t?.url ? "pi pi-youtube" : "",
                },
              ],
            }))
          : [],
      },
      {
        label: "Symptoms",
        type: SYMPTOM_LABEL,
        key: `${idx}-1`,
        data: c.symptoms,
        value: "Symptoms",
        selectable: false,

        className: `${c.symptoms.length > 0 ? "" : "hidden"} capitalize`,
        children: c.symptoms
          ? c.symptoms.map((s, ids) => ({
              className: "capitalize",
              label: s.label,
              value: s.label,
              key: `${idx}-1-${ids}`,
              id: s.id,
              type: SYMPTOM_ITEM,
              data: s,
              selectable: false,
            }))
          : [],
      },
      {
        label: "Mechanism Of Injury",
        selectable: false,
        type: MECH_OF_INJURY_LABEL,
        data: c.mechanismOfInjury,
        value: "Mechanism Of Injury",
        key: `${idx}-2`,
        className: `${
          c.mechanismOfInjury.length > 0 ? "" : "hidden"
        } capitalize`,
        children:
          c.mechanismOfInjury.length > 0 &&
          c.mechanismOfInjury.map((m, idm) => ({
            label: m.label,
            value: m.label,
            data: m,
            key: `${idx}-2-${idm}`,
            id: m.id,
            type: MECH_OF_INJURY_ITEM,
            selectable: false,
          })),
      },
      {
        label: "Other Info",
        className: `${c.otherInfo.length > 0 ? "" : "hidden"} capitalize`,
        data: c,
        selectable: false,
        type: OTHER_INFO_LABEL,
        value: "Other Info",
        key: `${idx}-3`,
        children:
          c.otherInfo.length > 0 &&
          c.otherInfo.map((i, idi) => ({
            key: `${idx}-3-${idi}`,
            data: i,
            label: i.label,
            value: i.label,
            id: i.id,
            type: OTHER_INFO_ITEM,
            selectable: false,
            // icon: "pi pi-info",
          })),
      },
    ],
  }))

  return nodes
}
