import { Toast } from "primereact/toast"
import React, { useRef, useEffect } from "react"
import { useGlobalToastStore } from "../../store/useGlobalToastStore"

export const GlobalToast = () => {
  const globalToastRef = useRef()
  const toast = useGlobalToastStore((state) => state.toast)
  useEffect(() => {
    if (toast) {
      globalToastRef.current.show({
        ...toast,
      })
    }
  }, [toast])

  return (
    <Toast
      id='GlobalToastId'
      baseZIndex={0}
      appendTo={document.body}
      ref={globalToastRef}
    />
  )
}
