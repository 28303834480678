import { Route, Routes, Navigate } from "react-router-dom"
import React, { lazy, Suspense } from "react"

import { AuthOutlet } from "./AuthOutlet"
import { Spinner } from "../components/spinner/Spinner"
import { useUserSettingsOnSnapshot } from "../hooks/useUserSettingsOnSnapshot"

const ClientsPage = lazy(() => import("../pages/clients-page/ClientsPage"))
const FeedbackPage = lazy(() => import("../pages/feedback-page/FeedbackPage"))
const NoMatchPage = lazy(() => import("../pages/no-match-page/NoMatchPage"))
const LandingPage = lazy(() => import("../pages/landing-page/LandingPage"))
const SettingsPage = lazy(() => import("../pages/settings-page/SettingsPage"))

const DashboardPage = lazy(() =>
  import("../pages/dashboard-page/DashboardPage")
)
const TreatmentPage = lazy(() =>
  import("../pages/treatment-page/TreatmentPage")
)

const Router = () => {
  // Get User Info, Settings, Clients, etc
  let user = useUserSettingsOnSnapshot()

  /*
    Only allow authorized users that are logged in to access protected routes.
    Otherwise, redirect back to Landing Page to sign in again.
  */
  return (
    <Routes>
      <Route path='/'>
        <Route
          index
          element={
            user ? (
              <Navigate to='/user/dashboard' />
            ) : (
              <Suspense fallback={<Spinner />}>
                <LandingPage />
              </Suspense>
            )
          }
        />
        <Route
          element={user ? <AuthOutlet /> : <Navigate to='/' />}
          path='user'>
          <Route
            element={
              <Suspense fallback={<Spinner />}>
                <DashboardPage />
              </Suspense>
            }
            path='dashboard'
          />
          <Route
            path='treatment'
            element={
              <Suspense fallback={<Spinner />}>
                <TreatmentPage />
              </Suspense>
            }
          />
          <Route
            path='clients'
            element={
              <Suspense fallback={<Spinner />}>
                <ClientsPage />
              </Suspense>
            }
          />
          <Route
            path='settings'
            element={
              <Suspense fallback={<Spinner />}>
                <SettingsPage />
              </Suspense>
            }
          />
          <Route
            path='feedback'
            element={
              <Suspense fallback={<Spinner />}>
                <FeedbackPage />
              </Suspense>
            }
          />
        </Route>

        <Route
          path='*'
          element={
            <Suspense fallback={<Spinner />}>
              <NoMatchPage />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  )
}
export default Router
