import {
  collection,
  arrayUnion,
  deleteDoc,
  updateDoc,
  where,
  query,
  doc,
  setDoc,
  getDoc,
  writeBatch,
  getDocs,
  onSnapshot,
  orderBy,
} from "firebase/firestore"
import { generateFirestoreId } from "../utils/nanoidUtils"
import {
  createDefaultNoteTabs,
  createDefaultSettings,
} from "../utils/generateNewUser"
import { firestore, auth } from "./firebase"
import sortOrder from "lodash/orderBy"

export const firestoreGetClientPreviousNotes = async (clientId) => {
  const { uid } = auth.currentUser
  const notesCollection = collection(
    firestore,
    `users/${uid}/userTreatmentNotes`
  )

  const clientQuery = query(notesCollection, where("clientId", "==", clientId))
  return await getDocs(clientQuery)
}

export const firestoreSetFeedbackDoc = async ({ comment, privateFeedback }) => {
  const { uid } = auth.currentUser
  const fColl = collection(firestore, `feedback`)

  const docId = generateFirestoreId()
  const id = generateFirestoreId()
  const key = generateFirestoreId()
  const docData = {
    comment,
    privateFeedback,
    id,
    key,
    userId: uid,
    docId,
    createdAt: new Date(),
  }
  const docRef = doc(fColl, docId)
  return await setDoc(docRef, docData)
}

export const firestoreGetSavedTreatmentNoteImages = async (sessionId) => {
  const { uid } = auth.currentUser
  const imageCollection = collection(
    firestore,
    `users/${uid}/userTreatmentNotes/${sessionId}/images`
  )

  const imageDocs = await getDocs(imageCollection)
  const isImagesEmpty = imageDocs.empty

  if (isImagesEmpty) {
    return null
  } else {
    let data = []
    imageDocs.docs.forEach((doc) => data.push({ docId: doc.id, ...doc.data() }))

    return data
  }
}

export const firestoreGetClientDoc = async (clientId) => {
  const { uid } = auth.currentUser
  const newClientRef = doc(firestore, `users/${uid}/userClients/${clientId}`)
  return await getDoc(newClientRef)
}

export const firestoreSetNewClientDoc = async ({
  firstName,
  lastName,
  modelSex,
}) => {
  const { uid } = auth.currentUser
  const docId = generateFirestoreId()
  const clientId = docId
  const newClientRef = doc(firestore, `users/${uid}/userClients/${clientId}`)
  const client = {
    firstName,
    lastName,
    name: `${firstName.length > 0 ? firstName : ""}${
      lastName.length > 0 ? ` ${lastName}` : ""
    }`,
    modelSex,
    createdAt: new Date(),
    uid: generateFirestoreId(),
    key: generateFirestoreId(),
    treatmentHistoryIds: [],
    docId,
    clientId,
  }
  try {
    await setDoc(newClientRef, client)
    return docId
  } catch (error) {
    console.log(error)
    return null
  }
}

export const firestoreUpdateDoc = async ({
  collectionName,
  docId,
  docData,
}) => {
  const { uid } = auth.currentUser
  const path = `users/${uid}/${collectionName}/${docId}`
  const ref = doc(firestore, path)
  return await updateDoc(ref, { ...docData }, { merge: true })
}

export const firestoreDeleteDoc = async ({ collectionName, docId }) => {
  const { uid } = auth.currentUser
  const path = `users/${uid}/${collectionName}/${docId}`
  const ref = doc(firestore, path)
  return deleteDoc(ref)
}

export const firestoreCheckAllowedEmailList = async ({ registerEmail }) => {
  const ref = doc(firestore, "allowedEmails/allowedEmails")
  const emails = await getDoc(ref)
  return emails.data().emails.includes(registerEmail)
}
export const firestoreCheckUserDocExists = async (uid) => {
  const userRef = doc(firestore, `users/${uid}`)
  const userDoc = await getDoc(userRef)
  return userDoc.exists()
}

export const firestoreCreateNewUser = async ({ uid, email }) => {
  if (!uid) return
  const noteSettingsArray = createDefaultNoteTabs()
  const userSettingsArray = createDefaultSettings()
  const userDocRef = doc(firestore, `users/${uid}`)

  const userSettingsCollectionRef = collection(
    firestore,
    `users/${uid}/userSettings`
  )
  const userNoteSettingsCollectionRef = collection(
    firestore,
    `users/${uid}/userNoteSettings`
  )
  const batch = writeBatch(firestore)
  try {
    await setDoc(userDocRef, {
      uid,
      email,
      createdAt: new Date(),
    })
  } catch (error) {
    console.log(error)
  }

  try {
    // Generate New User Settings
    noteSettingsArray.forEach((n) => {
      const docId = n?.docId ?? generateFirestoreId()
      const ref = doc(userNoteSettingsCollectionRef, docId)
      batch.set(ref, n)
    })
    userSettingsArray.forEach((s) => {
      const docId = s?.docId ?? generateFirestoreId()
      const ref = doc(userSettingsCollectionRef, docId)
      batch.set(ref, s)
    })
    return await batch.commit()
  } catch (error) {
    console.log(error)
  }
}

const imageDelete = async (imageDocs, imageCollection) => {
  await imageDocs.docs.forEach(async (d) => {
    const docRef = doc(imageCollection, d.id)
    await deleteDoc(docRef)
  })
}

export const firestoreSaveSessionToFirestore = async ({ images, docData }) => {
  const { uid } = auth.currentUser
  const sessionId = docData.sessionId
  const clientId = docData?.clientId ?? null

  const treatmentNotesRef = doc(
    firestore,
    `users/${uid}/userTreatmentNotes/${sessionId}`
  )
  try {
    //TODO: make use of firebase storage
    const imageCollection = collection(
      firestore,
      `users/${uid}/userTreatmentNotes/${sessionId}/images`
    )

    const imageDocs = await getDocs(imageCollection)
    const isImagesEmpty = imageDocs.empty
    if (!isImagesEmpty) {
      await imageDelete(imageDocs, imageCollection)
    }
    if (images.length > 0) {
      const imagesRef = collection(
        firestore,
        `users/${uid}/userTreatmentNotes/${sessionId}/images`
      )
      const batch = writeBatch(firestore)
      await images.forEach((d) => {
        const docId = generateFirestoreId()
        const docRef = doc(imagesRef, docId)
        batch.set(docRef, d)
      })
      await batch.commit()
    }
  } catch (error) {
    console.log(error)
  } finally {
    if (clientId) {
      const clientDoc = doc(firestore, `users/${uid}/userClients/${clientId}`)
      try {
        await updateDoc(clientDoc, {
          treatmentHistoryIds: arrayUnion(sessionId),
        })
      } catch (error) {
        console.log(error)
      }
    }
    return await setDoc(
      treatmentNotesRef,
      {
        ...docData,
        lastModified: new Date(),
      },
      { merge: true }
    )
  }
}
// export const batchWriteImages = async ({ images, imageCollection }) => {
//   const batch = writeBatch(firestore)
//   images.forEach((s) => {
//     const ref = doc(imageCollection, s.id)
//     batch.set(ref, s)
//   })
//   return await batch.commit()
// }

export const firestoreAddNewUserSetting = async ({ settingType, docData }) => {
  const { uid } = auth.currentUser
  const userSettingsCollectionRef = collection(
    firestore,
    `users/${uid}/userSettings`
  )
  const key = generateFirestoreId()
  const id = generateFirestoreId()
  const docId = generateFirestoreId()
  const newDocRef = doc(userSettingsCollectionRef, docId)
  return await setDoc(newDocRef, {
    settingType,
    key,
    id,
    docId,
    createdAt: new Date(),
    ...docData,
  })
}

export const firestoreUpdateUserSetting = async ({ docId, docData }) => {
  const { uid } = auth.currentUser
  const userSettingsDoc = doc(firestore, `users/${uid}/userSettings/${docId}`)

  return await updateDoc(userSettingsDoc, { ...docData }, { merge: true })
}

export const firestoreDeleteUserSetting = async (docId) => {
  const { uid } = auth.currentUser
  const userSettingsDoc = doc(firestore, `users/${uid}/userSettings/${docId}`)
  return await deleteDoc(userSettingsDoc)
}

export const firestoreGetImagesFromTreatment = async (sessionId) => {
  const { uid } = auth.currentUser
  const imagesRef = collection(
    firestore,
    `users/${uid}/userTreatmentNotes/${sessionId}/images`
  )
  return await getDocs(imagesRef)
}

/* User Settings onSnapshot */

export const userNoteSettingsOnSnapshot = ({ uid, setUserNoteSettings }) => {
  return onSnapshot(
    collection(firestore, `users/${uid}/userNoteSettings`),
    (query) => {
      let data = []
      query.docs.forEach((doc) => data.push({ ...doc.data() }))
      setUserNoteSettings(sortOrder(data, "orderIndex", "asc"))
    },
    (error) => {
      console.log(error)
    }
  )
}

export const userSettingsOnSnapshot = ({ uid, setUserSettings }) => {
  return onSnapshot(
    collection(firestore, `users/${uid}/userSettings`),
    (query) => {
      let data = []
      query.docs.forEach((doc) => data.push({ ...doc.data() }))
      setUserSettings(data)
    },
    (error) => {
      console.log(error)
    }
  )
}

export const userClientsOnSnapshot = ({ uid, setUserClients }) => {
  return onSnapshot(
    query(collection(firestore, `users/${uid}/userClients`), orderBy("name")),
    (query) => {
      let data = []
      query.docs.forEach((doc) => data.push({ ...doc.data() }))
      setUserClients(data)
    },
    (error) => {
      console.log(error)
    }
  )
}

export const userDraftTreatmentNotesOnSnapshot = ({ uid, setUserDrafts }) => {
  return onSnapshot(
    query(
      collection(firestore, `users/${uid}/userTreatmentNotes`),
      where("isFinal", "==", false)
    ),
    (query) => {
      let data = []
      query.docs.forEach((doc) =>
        data.push({
          ...doc.data(),
          dateOrder: doc.data()?.lastModified.toDate(),
        })
      )
      setUserDrafts(sortOrder(data, "dateOrder", "desc"))
    },
    (error) => {
      console.log(error)
    }
  )
}
