import React, { useState } from "react"
import { Menu } from "primereact/menu"
import { Sidebar } from "primereact/sidebar"
import { Button } from "primereact/button"
import { useNavigate } from "react-router-dom"
import { authSignOut } from "../../firebase/fireauth"
import { useGlobalToastStore } from "../../store/useGlobalToastStore"
import { useCurrentSessionStore } from "../../store/useCurrentSessionStore"
import { AppFooter } from "../app-footer/AppFooter"

export const AppSidebar = () => {
  let navigate = useNavigate()
  const setSuccess = useGlobalToastStore((state) => state.setSuccess)
  const clearAndRestartCurrrentSession = useCurrentSessionStore(
    (state) => state.clearAndRestartCurrrentSession
  )
  const [visible, setVisible] = useState(false)

  const customIcons = (
    <div className='flex w-full align-items-center justify-content-center'>
      <div className='text-white font-semibold '>ProHealthNotes</div>
    </div>
  )
  let items = [
    {
      label: "Home",
      icon: "pi pi-fw pi-home",
      // className: "border-1 border-round surface-border m-1",

      command: () => {
        navigate("/user/dashboard")
        setVisible(false)
      },
    },
    {
      label: "Treatment",
      icon: "pi pi-fw pi-pencil",
      // className: "border-1 border-round surface-border m-1",

      command: () => {
        navigate("/user/treatment")
        setVisible(false)
      },
    },
    {
      label: "Settings",
      icon: "pi pi-fw pi-cog",
      // className: "border-1 border-round surface-border m-1",

      command: () => {
        navigate("/user/settings")
        setVisible(false)
      },
    },
    {
      label: "Clients",
      icon: "pi pi-fw pi-users",

      command: () => {
        navigate("/user/clients")
        setVisible(false)
      },
    },
    {
      label: "Feedback",
      icon: "pi pi-fw pi-comments",

      command: () => {
        navigate("/user/feedback")
        setVisible(false)
      },
    },
    {
      label: "Sign Out",
      icon: "pi pi-fw pi-sign-out",
      // className: "border-1 border-round surface-border m-1",
      command: () => {
        setVisible(false)
        authSignOut().then(() => {
          clearAndRestartCurrrentSession()
          setSuccess("Signed Out!")
        })
      },
    },
  ]
  return (
    <div className='flex align-items-center justify-content-start'>
      <Button
        icon='pi pi-bars'
        id='SideBarMenuButton'
        onClick={() => setVisible(true)}
      />
      <div className='align-items-center justify-content-start pl-1 AppHeader__InnerText'>
        <div className='text-white font-semibold '>ProHealthNotes</div>
      </div>
      <Sidebar
        id='HeaderSidebar'
        style={{
          width: "220px",
          margin: 0,
          padding: 0,
        }}
        icons={customIcons}
        modal
        className='p-0 m-0'
        showCloseIcon={false}
        dismissable
        blockScroll
        position='left'
        visible={visible}
        onHide={() => setVisible(false)}>
        <div className='flex h-full flex-column w-full'>
          <div className='flex flex-column h-full w-full justify-content-start align-items-center '>
            <div className='flex w-full align-items-center my-1'>
              <Menu className='SidebarMenu w-full' model={items} />
            </div>
          </div>
          <div className='flex flex-column p-1  border-round surface-border border-1'>
            <AppFooter />
          </div>
        </div>
      </Sidebar>
    </div>
  )
}
