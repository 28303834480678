import { create } from "zustand"

import { subscribeWithSelector } from "zustand/middleware"

import { generateTinyId } from "../utils/nanoidUtils"

export const useCameraStore = create(
  subscribeWithSelector((set) => ({
    enabled: true,
    enableControls: () => set({ enabled: true }),
    disableControls: () => set({ enabled: false }),
    recenterCamera: null,
    triggerRecenterCamera: () => {
      const id = generateTinyId()
      set({ recenterCamera: id })
    },
    resetCameraStore: () =>
      set({
        recenterCamera: null,
        enabled: true,
      }),
  }))
)
