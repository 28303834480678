import {
  TAB_ONE,
  TAB_THREE,
  TAB_TWO,
  TAB_FOUR,
  TAB_FIVE,
  TAB_SIX,
  GENERAL_AREAS_MODE,
  PIN_MODE,
  SELECT_MODE,
  RADIO,
  QUICK_BTN,
  TREATMENT_BTN,
  QUESTION,
} from "../constants/constants"
import { generateFirestoreId, generateSmallId } from "./nanoidUtils"

export const createDefaultSettings = () => {
  const settings = [
    {
      id: generateFirestoreId(),
      docId: generateFirestoreId(),

      key: generateFirestoreId(),
      question: "Do you get headaches?",
      answerType: RADIO,
      settingType: QUESTION,
      answers: [
        {
          id: generateFirestoreId(),
          uid: generateFirestoreId(),
          key: generateFirestoreId(),
          answerKey: generateSmallId(),
          answer: "Never",
        },
        {
          id: generateFirestoreId(),
          uid: generateFirestoreId(),
          key: generateFirestoreId(),
          answerKey: generateSmallId(),
          answer: "Weekly",
        },
        {
          id: generateFirestoreId(),
          uid: generateFirestoreId(),
          key: generateFirestoreId(),
          answerKey: generateSmallId(),
          answer: "Daily",
        },
        {
          id: generateFirestoreId(),
          uid: generateFirestoreId(),
          key: generateFirestoreId(),
          answerKey: generateSmallId(),
          answer: "2-3 times a week",
        },
      ],
    },
    {
      key: generateFirestoreId(),
      question: "Do you get pins and needles?",
      answerType: RADIO,
      settingType: QUESTION,
      id: generateFirestoreId(),
      docId: generateFirestoreId(),

      answers: [
        {
          id: generateFirestoreId(),
          uid: generateFirestoreId(),
          key: generateFirestoreId(),
          answerKey: generateSmallId(),
          answer: "Never",
        },
        {
          id: generateFirestoreId(),
          uid: generateFirestoreId(),
          key: generateFirestoreId(),
          answerKey: generateSmallId(),
          answer: "Weekly",
        },
        {
          id: generateFirestoreId(),
          uid: generateFirestoreId(),
          key: generateFirestoreId(),
          answerKey: generateSmallId(),
          answer: "Daily",
        },
        {
          id: generateFirestoreId(),
          uid: generateFirestoreId(),
          key: generateFirestoreId(),
          answerKey: generateSmallId(),
          answer: "2-3 times a week",
        },
      ],
    },
    {
      label: "Pain",
      output: "Client complains of pain in: ",
      docId: generateFirestoreId(),

      key: generateFirestoreId(),
      id: generateFirestoreId(),
      uid: generateFirestoreId(),
      settingType: QUICK_BTN,
    },
    {
      label: "Eff",
      output: "Effluerage:",
      eventMode: GENERAL_AREAS_MODE,
      docId: generateFirestoreId(),

      id: generateFirestoreId(),
      uid: generateFirestoreId(),
      key: generateFirestoreId(),
      noteId: null,
      settingType: TREATMENT_BTN,
    },
    {
      label: "TrP",
      output: "Trigger Point:",
      eventMode: PIN_MODE,
      docId: generateFirestoreId(),

      id: generateFirestoreId(),
      uid: generateFirestoreId(),
      key: generateFirestoreId(),
      noteId: null,
      settingType: TREATMENT_BTN,
    },
    {
      label: "MFR",
      output: "Myofascial Release:",
      eventMode: SELECT_MODE,
      noteId: null,
      docId: generateFirestoreId(),

      id: generateFirestoreId(),
      uid: generateFirestoreId(),
      key: generateFirestoreId(),
      settingType: TREATMENT_BTN,
    },
  ]

  return settings
}

export const createDefaultNoteTabs = () => {
  const noteArray = [
    {
      abbr: "Sub",
      label: "Subjective",
      id: generateFirestoreId(),
      uid: generateFirestoreId(),
      key: generateFirestoreId(),

      docId: generateFirestoreId(),

      noteId: TAB_ONE,
      postureTab: false,
      orderIndex: 0,
    },
    {
      abbr: "Obj",
      label: "Objective",
      id: generateFirestoreId(),
      uid: generateFirestoreId(),
      key: generateFirestoreId(),

      docId: generateFirestoreId(),

      orderIndex: 1,
      postureTab: false,
      noteId: TAB_TWO,
    },
    {
      abbr: "Assess",
      label: "Assessment",
      id: generateFirestoreId(),
      uid: generateFirestoreId(),
      key: generateFirestoreId(),

      docId: generateFirestoreId(),

      noteId: TAB_THREE,
      orderIndex: 2,
      postureTab: false,
    },
    {
      abbr: "Plan",
      label: "Plan",
      id: generateFirestoreId(),
      uid: generateFirestoreId(),
      noteId: TAB_FOUR,
      key: generateFirestoreId(),

      docId: generateFirestoreId(),

      orderIndex: 3,
      postureTab: false,
    },
    {
      abbr: "Posture",
      label: "Postural Assessment",
      id: generateFirestoreId(),
      uid: generateFirestoreId(),
      noteId: TAB_SIX,

      key: generateFirestoreId(),

      docId: generateFirestoreId(),

      postureTab: true,
      orderIndex: 5,
    },
    {
      abbr: "Custom",
      label: "Customise In Settings",
      id: generateFirestoreId(),
      uid: generateFirestoreId(),
      key: generateFirestoreId(),

      docId: generateFirestoreId(),
      noteId: TAB_FIVE,

      postureTab: false,
      orderIndex: 4,
    },
  ]

  return noteArray
}
